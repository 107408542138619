import React from "react";
import { Link } from "react-router-dom";

const DashboardActions = () => {
  return (
    <div className="dash-buttons">
      <Link to="/edit-profile" className="btn btn-light">
        <i className="fas fa-user-circle text-primary"></i> Edit Profile
      </Link>
      <Link to="/add-racing-resume" className="btn btn-light">
        <i className="fas fa-flag-checkered text-primary"></i> Add Racing Resume
      </Link>
      <Link to="/add-favorite-routes" className="btn btn-light">
        <i className="fas fa-route text-primary"></i> Add Favorite Routes
      </Link>
    </div>
  );
};

export default DashboardActions;
